@import "src/style/mixin.scss";
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  word-wrap: break-word;
  font-family: "Cairo", sans-serif;
}
*::-webkit-scrollbar {
  width: 3px;
}
*::-webkit-scrollbar-track {
  background: $primary-white;
}
*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(250, 250, 250, 0.5);
  border: 1px solid $primary-grey;
}

a:-webkit-any-link,
a {
  color: $primary-black;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: $primary-dark-black;
  }
}
body {
  height: 100vh;
  &::-webkit-scrollbar-track {
    background: $secondary-grey;
  }
}
.App {
  min-height: 100vh;
  background: $secondary-grey;
  position: relative;
}
.mini-sign {
  @include bg(6, none, $primary-white);
  @include flex(center, 10, row, center);
  min-height: 30px;
  min-width: 94px;
  padding: 3px 16px;
  border: 1px solid $primary-light-black;
  display: none;
  @media (max-width: 360px) {
    min-width: auto;
  }
}
.modal {
  height: inherit;
  position: relative;
  padding: 50px;
  width: 800px;
  margin: 85px auto;
  background: $primary-white;
  border-radius: 10px;
  &__title {
    margin-bottom: 20px;
  }

  &:focus-visible {
    outline: none
  }
}

.right-float-modal {
  margin: inherit;
  margin-left: auto;
  padding: 0;
  border-radius: inherit;
}

.green {
  color: #5cdb47;
}
.light-green {
  color: $primary-green;
}
@for $i from 1 through 5 {
  .col-#{$i} {
    display: grid;
    grid-template-columns: repeat($i, minmax(0, 1fr));
    @if $i > 2 {
      gap: 5px;
    } @else {
      gap: 10px;
    }
  }
}
.input-button {
  @include flex(center, 8, row, center);
}
.flex {
  &_CS {
    @include flex(center);
  }
  &_CC {
    @include flex(center, $justifyContent: center);
  }
}

.loading-container {
  width: 100%;
  height: 200px;
  text-align: center;
  margin-top: 100px;
}