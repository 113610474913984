@import 'src/style/mixin.scss';

.login-modal {
  position: relative;
  width: 400px;
  margin: 85px auto;
  padding: 40px;
  text-align: center;
  background: #ffffff;
  border-radius: 10px;

  &__title {
    color: #4b566b;
    font-weight: 800;
    margin-bottom: 30px;
  }

  &__signup {
    margin-bottom: 10px;
    @include font(16, $primary-light-black, 400);

    &__text {
      @include font(20, $primary-light-black, 600);
      margin-bottom: 30px;
    }

    b {
      cursor: pointer;
      margin-left: 5px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__buttons {
    @include flex(center, 10, row, center);
    margin-top: 30px;

    button {
      width: 100%;
    }
  }
}

@media screen and (max-width: 430px) {
  .login-modal {
    width: 300px;
    padding: 30px;

    &__title {
      margin-bottom: 20px;
    }

    &__buttons {
      flex-direction: column;
    }
  }
}