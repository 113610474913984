@import "src/style/mixin.scss";

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  @include flex(center);
  width: 100%;
  height: 100%;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
.mySwiper {
  width: 100%;
  height: 100%;
}
.swiper-zoom-container > img {
  @media (min-width: 720px) {
    max-width: unset;
    max-height: unset;
    object-fit: unset;
  }
}
