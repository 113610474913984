@import 'src/style/mixin.scss';

.actions-card {
  
  &__container {
    @include flex(center, 10, column, center);

    &__item {
      width: 100%;
      @include flex(center, 10, row, space-between);
      padding: 10px;
      @include font(19, $primary-black, 600);
      border-radius: 4px;
      background-color: rgba(250, 251, 254, 1);

      &__title {
        @include flex(center, 10, row, center);
      }

      svg {
        font-size: 15px;
      }
    }

    &__expand {
      width: 100%;
      border-radius: 4px;
      background-color: rgba(250, 251, 254, 1);

      &__edit {
        @include flex(flex-end, 10, column, center);
        padding: 10px;

        &.show {
          display: flex;
        }

        &.hide {
          display: none;
        }

        & > div {
          width: 100%;
        }
      }
    }
  }
}

.rotate {
  animation-duration: 1s;
  animation-name: rotate;
  animation-iteration-count: 1;
  transform: rotate(90deg);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(90deg);
  }
}