@import 'src/style/mixin';

.canvas-sign {
  @include bg();
  padding: 20px;
  margin: 10px auto 0;
  max-width: 400px;
  button {
    padding: 8px 16px;
  }


}
.react-sketch-canvas {
  border-radius: 3px;
  border: 1px solid $primary-green;
  margin-top: 10px;
}
