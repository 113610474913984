@import 'src/style/mixin.scss';

@media screen and (max-width: 1500px) {
  .client {
   
    &__order {

      &__main {

        &__cards {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}

@media screen and (max-width: 1260px) {
  .client {
    margin-top: 60px;
  }
}

@media screen and (max-width: 1100px) {
  .client {
   
    &__orders {
      padding: 40px 5px;

      &__subtitle {
        margin-left: 10px;
      }

      h3 {
        margin-left: 10px;
      }
    }

    &__order {

      &__main {
        padding: 0;

        &__cards {
          display: flex;
          flex-direction: column;

          .common-card {
            order: 0;
          }

          .client-card {
            order: 1;
          }

          .articles-card {
            order: 2;
          }

          .payments-card {
            order: 3;
          }

          .documents-card {
            order: 4;
          }

          .drivers-card {
            order: 5;
          }

          .actions-card {
            order: 6;
          }

          .note-card {
            order: 7;
          }
        }
      }

      &__steps {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 740px) {
  .client {
    
    &__order {

      &__main {

        &__van {
          display: none;
        }
      }

      &__container {
        flex-direction: column;
        gap: 0;

        &__payment {
          width: 100%;
          padding-bottom: 20px;
          margin-bottom: 20px;
          border-right: none;
          border-bottom: 1px solid #ccc;

          &__main {
            min-height: auto;
          }
        }

        &__drivers {
          width: 100%;
          padding-bottom: 20px;
          margin-bottom: 20px;
          border-right: none;
          border-bottom: 1px solid #ccc;

          &__items {
            min-height: auto;
          }
        }

        &__documents {
          width: 100%;

          &__main {
            min-height: auto;
          }
        }
      }

      &__footer {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}

@media screen and (max-width: 715px) {
  .client {
   
    &__order {

      &__accordion {

        &__container {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .client {
   
    &__order {

      &__header {
       display: none;
      }

      &__main {

        h3 {
          margin: 0 auto 20px auto;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .client {
   
    &__info {

      &__main {
        display: none;
      }

      &__secondary {
        display: none;
      }
    }

    &__order {

      &__main {
  
        h3 {
          font-size: 25px;
        }
      }
    }
  }
}

@media screen and (max-width: 340px) {
  .client {

    &__orders {
      justify-content: center;
      padding: 20px 0;
    }

    &__no-booking {

      &__header h2 {
        font-size: 23px;
      }

      &__text p {
        font-size: 18px;
      }
    }
  }
}