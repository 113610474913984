@import "src/style/variables.scss";

@mixin bg($br: 7, $bsh: $primary-shadow, $bg: $primary-white, $border: none) {
  border-radius: $br + px;
  background: $bg;
  box-shadow: $bsh;
  border: $border;
}

@mixin flex(
  $alignItems: flex-start,
  $gap: 0,
  $flexDirection: row,
  $justifyContent: flex-start
) {
  display: flex;
  align-items: $alignItems;
  flex-direction: $flexDirection;
  gap: $gap + px;
  justify-content: $justifyContent;
}

@mixin img {
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@mixin font(
  $fontSize: 14,
  $fontColor: $primary-black,
  $fontWeight: 400,
  $fontHeight: $fontSize
) {
  font-size: $fontSize + px;
  color: $fontColor;
  font-weight: $fontWeight;
  line-height: $fontHeight + px;
  @media screen and (max-width: 991px) {
    font-size: ($fontSize * 0.9) + px;
    line-height: ($fontHeight * 0.9) + px;
  }
}

@mixin hover(
  $bg: none,
  $color: $primary-black,
  $border: none,
  $boxShadow: none
) {
  &:hover {
    background: $bg;
    color: $color;
    border: $border;
    box-shadow: $boxShadow;
    cursor: pointer;
  }
}
@mixin bg-off($br: 7, $bsh: none, $bg: none, $border: none) {
  border-radius: $br + px;
  background: $bg;
  box-shadow: $bsh;
  border: $border;
}
@mixin bg-box() {
  @include flex(center, 4, row, center);
  @include font(16, $primary-black, 500);
  align-items: center;
  padding: 6px 12px;
  height: 30px;
  border: 1px solid $primary-grey;
  border-radius: 5px;
  cursor: pointer;
}
