@import 'src/style/mixin.scss';

.client {
  min-height: 100vh;
  @include flex(center, 0, column, space-between);

  &__orders {
    width: 100%;
    @include flex(center, 20, column, center);
    padding: 40px;

    &__subtitle {
      @include font(16, $primary-black, 500, 10);
      margin-bottom: 20px;
    }

    & > div {
      width: 100%;
    }
  }

  &__order {
    width: 100%;

    &__accordion {
      @include flex(center, 20, row, space-between);

      &__container {
        @include flex(center, 20, row, space-between);
      }

      &__info {
        @include flex(center, 10, row, space-between);
        @include font(14, $primary-black, 600);
      }

      &__note {
        @include font(14, #8891A4, 400);
      }

      h5 {
        @include font(20, $primary-black, 600);
      }
    }

    &__header {
      @include flex(center, 20, column, center);
      padding: 40px 0;
      text-align: center;
      background-color: $secondary-blue;

      &__badges {
        @include flex(center, 10, row, center);
      }

      &__badge {
        width: 188px;
        height: 41px;
        @include flex(center, 10, row, center);
        border-radius: 10px;

        &__status {
          width: 109px;
          padding: 0 30px;
          text-align: center;
          border-radius: 6px;
          font-weight: 700;

          &.partial {
            background-color: $primary-light-grey;
            color: $primary-black;
            &__document {
              border: 1px solid $primary-light-grey;
              color: $primary-light-grey;
            }
          }
          &.leads {
            background-color: rgba(0, 0, 255, 0.1);
            color: $primary-blue;
            &__document {
              border: 1px solid $primary-blue;
              color: $primary-blue;
            }
          }
          &.paid {
            background-color: $primary-green;
            color: $primary-black;
            &__document {
              border: 1px solid $primary-green;
              color: $primary-green;
            }
          }
          &.ready {
            font-weight: 600;
            color: $primary-black;
            background-color: #39db39;
          }
        }

        span {
          @include font(16, $secondary-blue, 700);
        }

        &:first-child {
          background-color: $primary-green;
        }

        &:last-child {
          background-color: $primary-white;
        }
      }

      h3 {
        @include font(32, $primary-white, 700);
      }

      h6 {
        @include font(16, $primary-white, 500);
      }
    }

    &__steps {
      text-align: center;
      padding: 40px 0 60px;
      background-color: #F0F2F6;

      &__container {
        height: 220px;
        @include flex(center, 25, row, center);
      }

      h3 {
        margin-bottom: 30px;
        @include font(24, $secondary-blue, 500);
      }
    }

    &__main {
      padding: 20px;

      &__cards {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 10px;

        & > div {
          width: 100%;
          @include bg();
          padding: 20px;
        }
      }

      &__header {
        width: 100%;
        @include flex(flex-end, 10, row, space-between);
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #ccc;

        &__edit {
          height: 24px;
        }

        &__container {
          @include flex(flex-end, 15, row, center);
        }

        &__actions {
          @include flex(center, 10, row, center);

          &__icon {
            height: 22px;
          }
        }

        button {
          padding: 6px 8px !important;
          border: 1px solid rgba(225, 226, 234, 1);
          background-color: rgba(250, 251, 254, 1) !important;
          @include font(12, $secondary-blue, 700);
        }

        h4 {
          margin-bottom: 0;
          @include font(20, $primary-black, 700);
        }

        span {
          @include font(14, rgb(125, 135, 156), 300);
        }
      }

      h3 {
        text-align: center;
        margin: 40px auto;
        @include font(32, $primary-black, 700);
      }

      h5 {
        @include font(16, $primary-black, 400, 20);

        b {
          margin-right: 3px;
        }
      }

      button {
        height: 30px;
      }
  
      svg {
        cursor: pointer;
      }
    }
  }

  &__info {
    width: 100%;
    padding-bottom: 100px;

    &__main {
      text-align: center;
      padding: 15px 0;
      background-color: #C2FF45;

      &__container {
        width: 400px;
        @include flex(flex-start, 0, column, center);
        margin: 0 auto;
      }

      &__item {
        @include flex(center, 10, row, center);

        svg {
          color: $primary-black;
        }

        &:first-child {
          border-bottom: 1px solid #4b566b5e;
          padding-bottom: 15px;
          margin-bottom: 15px;
        }
      }
    }

    &__secondary {
      text-align: center;
      padding: 30px 0;

      &__container {
        width: 400px;
        @include flex(flex-start, 10, column, center);
        margin: 0 auto;
      }

      &__item {
        @include flex(center, 10, row, center);
      }
    }

    h5 {
      @include font(16, $primary-black, 700);
    }
  }

  &__no-booking {
    width: 100%;

    &__header {
      text-align: center;
      padding: 50px 0;
      background-color: $secondary-blue;

      h2 {
        padding: 0 10px;
        @include font(32, $primary-white, 700);
      }
    }

    &__text {
      margin-top: 50px;
      p {
        width: fit-content;
        margin: 0 auto;
        @include font(22, $primary-black, 500, 36);
      }

      span {
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          color: #091b3d;
        }
      }

      a {
        text-decoration: underline !important;
      }
    }
  }

  &__modal {
    height: inherit;
    position: relative;
    background: $primary-white;
    border-radius: 10px;

    &__title {
      @include font(20, $primary-black, 700);
      text-align: center;
      margin-bottom: 40px;
    }

    &:focus-visible {
      outline: none
    }
  }
}

.icons-container {
  @include flex(center, row, center);
}

.green {
  color: #01c38d;
}
.red {
  color: #d24057;
}
.blue {
  color: #151c97;
}