@import "src/style/mixin.scss";

.logo {
  width: 100%;
  max-width: 100px;
  text-align: center;

  &__img {
    width: 110px;
  }

  &__small-img {
    width: 70px;
  }

  svg {
    filter: drop-shadow(1px -1px 1px $primary-black);
  }

  &:active {
    transform: translateY(2px);
  }
}
