@import "src/style/mixin.scss";

.vans-time {
  @include flex(flex-start, 16, column);
  grid-row: 1/4;
  grid-column: 2/5;
}
.vans {
  & > div {
    margin-bottom: 10px;
  }
  & > div > div {
  width: 100%;
  @include flex(flex-start, 16, column);
  }

  &__novans {
    font-size: 23px;
    color: #4b566b;
    font-weight: 700;
    line-height: 30px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 50px;

    p {
      margin-bottom: 20px;
    }
  }

  &__locations-nearby {
    font-size: 15px;
    color: #2B3445;
    font-weight: 600;
    padding: 6px 20px;
    border-radius: 5px;
    background: #7f899d47;
    width: 100%;
    margin-top: 10px;
    align-items: center;
    display: inline-flex;

    &__location-from {
      border-radius: 5px;
      background: #FFFFFF66;
      padding-left: 4px;
      padding-right: 9px;
      margin-left: 7px;
      align-items: center;
      display: inline-flex;
      gap: 7px;

      &__img {
        background: #FFF;
        border-radius: 5px;
        align-items: center;
        display: inline-flex;
        padding: 3px;

        svg {
          width: 14px;
          height: 14px;
        }
      }

    }
    
    @media (max-width: 600px) {
      font-size: 15px;
      line-height: 20px;
    }

    @media (max-width: 450px) {
      display: block;
      text-align: center;
    }
  }
}

@media screen and (max-width: 1261px) {
  .vans {
    & > div > div {
    gap: 10px;
    }
  }
  .vans-time {
    padding: 0px 10px 10px 10px;
    width: 100%;
    gap: 10px;
    grid-row: unset;
    grid-column: unset;
  }
  button.back-to-top {
    @include bg();
    @include font();
    min-width: unset;
    width: 45px;
    height: 45px;
    padding: unset !important;
    position: fixed;
    bottom: 40px;
    right: 40px;
    color: $primary-black;
    cursor: pointer;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.9);
    svg {
      transform: translateY(-2px);
    }
  }
}

@media screen and (max-width: 470px) {
  .vans-time {
    padding: 0px 10px 10px 10px;
  }
}
.closeIcon {
  position: absolute;
  top: 30px;
  right: 30px;
  color: white;
  cursor: pointer
}

.infiniteScroll {
  overflow: hidden !important;

  &__loading {
    text-align: center;
    margin-top: 20px;
  }
}