@import "src/style/mixin.scss";

.aside {
  top: 16%;
  position: sticky;
  width: 270px;
  @include flex(center, 20, column);
  display: inline-flex;
}
@media screen and (max-width: 1261px) {
  .aside {
    display: none;
  }
}
