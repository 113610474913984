@import 'src/style/mixin.scss';

@media screen and (max-width: 350px) {
  .documents-card {

    &__footer {

      &__container {
        display: none;
      }

      button {
        width: 100%;
      }
    }
  }
}