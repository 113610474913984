@import 'src/style/mixin.scss';

.share-modal {
  height: inherit;
  position: relative;
  background: #d8e3e2;
  border-radius: 10px;
  
  &__copy-link {
    width: 40px;
    min-width: auto !important;

    &__copy-icon {
      position: absolute;
    }

    &__circle-icon {
      font-size: 3.2em !important;
      fill: #c1ff45 !important;
    }
  }
  
  &__items {
    height: 80px;
    width: 320px;
    padding: 20px;
    @include flex(center, 10, row, center);

    button {
      @include flex(center, 0, row, center);
      transition: all 0.5s ease 0s;
      background-color: transparent !important;
      border: none;
      padding: 0px;
  
      &:hover {
        margin-bottom: 10px;
      }
    }
  }

  &.down {
    margin: auto auto 30px auto;
  }

  &.up {
    margin: 60px 135px auto auto;
  }

  &:focus-visible {
    outline: none
  }
}