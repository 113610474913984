@import 'src/style/mixin';

.driver-license {
  max-width: 500px;
  margin: 0 auto;
  padding: 50px 10px 0;
  &__wrapper {
    @include bg();
    width: 100%;
    padding: 20px;
  }
  &__title {
    @include font(28, $primary-dark-black, 600);
    margin-bottom: 10px;
  }
  &__description {
    margin-top: 10px;
    p {
      @include font(14, $primary-black, 500, 20);
    }
  }
  .terms {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
  }
}
@media screen and (max-width: 991px) {
  .driver-license {
    padding: 10px;
    width: 100%;
    &__wrapper {
      & > div.col-2 {
        grid-template-columns: 1fr;
      }
    }
  }
}
