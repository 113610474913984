@import 'src/style/mixin.scss';

.help {
  position: fixed;
  cursor: pointer;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(236, 238, 16);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
  transition-duration: .15s;

  svg {
    width: 30px;
    height: 30px;
    margin-top: 12px;
    margin-left: 11px;
    color: $primary-black;
  }

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.75);
  }
}