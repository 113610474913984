@import "src/style/mixin.scss";

@media screen and (max-width: 991px) {
  .MuiStepper-root {
  }
  .MuiStep-root {
    font-size: 12px !important;
    text-align: center;
  }
}

.container {
  width: 540px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 100px;
  @media (max-width: 991px) {
    width: 100%;
    padding-right: 0;
  }
}