$primary-green: #c1ff45;
$primary-red: #d24057;
$primary-violet: #c04fff;
$primary-blue: #151c97;
$secondary-blue: #081B3D;
$primary-black: #4b566b;
$primary-dark-black: #091b3d;
$primary-light-black: #686b73;
$primary-white: #ffffff;
$primary-grey: #ccc;
$primary-light-grey: rgba(204, 204, 204, 0.15);
$secondary-grey: #fafbfe;

$primary-shadow: (
  0 0 0 0.5px rgba(50, 50, 93, 0.05),
  0 2px 5px 0 rgba(50, 50, 93, 0.05),
  0 1px 1.5px 0 rgba(0, 0, 0, 0.05)
);
