@import "src/style/mixin.scss";

@media screen and (max-width: 500px) {

  .small-footer {
    justify-content: center;
    flex-wrap: wrap-reverse;

    .logo {
      display: none;
    }
  }
}