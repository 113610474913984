@import 'src/style/mixin.scss';

.driver-docs {
  height: 36px;
  @include flex(center, 5, row, center);
  padding: 3px 5px;
  @include bg($bg: #f1f4fc);

  &__container {
    min-height: 27px;
    @include flex(center, 10, row, center);
    padding: 3px 5px;
    @include bg($br: 3);
  }

  &__license {
    @include flex(center, 0, row, center);
  }

  &__delete {
    height: 22px;
    cursor: pointer;
  }

  @media (max-width: 520px) {
    width: auto;

    &__container {
      width: auto;
      height: 20px;
      gap: 0;
    }
  }
}