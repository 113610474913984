@import "src/style/mixin.scss";

@media screen and (max-width: 910px) {
  .van {
    &__info {
      &__description {
        margin-bottom: 10px;
      }
    }

    &__capacity {
      margin-bottom: 10px;
    }

    &__order {

      &__container {
        gap: 20px;
      }

      &__extras {
        gap: 10px;
      }

      &__extra {

        &__item {
          gap: 5px;
        }

        &__delivery {
          width: 75px;

          &__text {
            width: 60px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .van {
    &__card {
      @include flex(center, 0, column, center);
      width: 100%;
      min-height: 200px;
      padding: 16px;
      gap: 5px;

      &>div {
        width: 100%;
        position: relative;

        &:nth-of-type(2) {
          padding: 0;
        }
      }
    }

    &__imgs {
      display: flex;
      gap: 10px;
      width: auto !important;
      margin-left: auto;
      margin-right: auto;
    }

    &__main-imgs {
      width: 290px;
      height: 217.5px;
    }

    &__capacity {
      padding: 5px 0;
    }

    &__info {

      &__imgs {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 106px;
      }

      &__extra {
        @include flex(center, 0, row, space-evenly);
        letter-spacing: 1px;

        &__item {
          @include flex(center, 4);
        }
      }

      &__price {
        color: $primary-dark-black !important;
        font-size: 20px;

        &__description {
          margin-top: 5px;
        }
      }

      &__mobileImgs {
        @include flex(center, 15, row, center);
        height: 120px;
        margin-top: 15px;
        margin-bottom: 15px;

        img {
          display: block;
          background-color: $primary-white;
          max-width: 190px;
          // min-width: 165px;
          max-height: 100%;
          border-radius: 3px;
          @include bg(5);
          cursor: pointer;

          @media (max-width: 380px) {
            max-width: 120px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 635px) {
  .van {
    &__info {

      &__price {
        font-size: 19px;
        padding-left: 5px;
        border-left: 0.6px solid #CFD2DA;
      }
    }
  }
}

@media screen and (max-width: 541px) {
  .van {

    &__main-imgs {
      width: 250px;
      height: 187.5px;
    }

    &__info {

      &__imgs {
        height: 92px;
      }
    }

    &__capacity {

      &__title {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 530px) {
  .van {
    &__imgs {
      display: block;
      margin-bottom: 10px;
    }

    &__main-imgs {
      margin-bottom: 10px;
    }

    &__info {
      gap: 5px;

      &__imgs {
        flex-direction: row;
        justify-content: center;
        height: 79px;
      }
    }

    &__capacity {
      margin-bottom: 15px;
    }

    &__order {
      flex-direction: column-reverse;
      gap: 20px;

      &__extra {
        &__item {
          width: auto;
          gap: 12px;
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 431px) {
  .sub-locations {

    &__items {
      gap: 5px;
      width: 347px;
      overflow: auto;
      outline: none;
      overflow-y: hidden;
      -ms-overflow-style: scroll;
      scrollbar-width: none;
      margin: 0 20px;
    }
  }
  .sub-locations::-webkit-scrollbar {
    display: none;
  }
  .van {
    &__capacity {

      &__container {
  
        &__items {
          width: 347px;
        }
      }
    }

    &__order {

      &__delivery-container {
        justify-content: center;
        flex-direction: column-reverse;
        flex-wrap: wrap;
      }

      &__extra {

        &__item {
          gap: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .van {
    &__card {
      padding: 10px;
    }

    &__info {
      grid-template-columns: auto 22px 80px;

      &__name {
        font-size: 17px;
      }

      &__price {
        font-size: 17px;
      }
    }
  }
}

@media screen and (max-width: 391px) {
  .sub-locations {

    &__items {
      width: 310px;
    }
  }

  .van {
    &__capacity {

      &__container {
  
        &__items {
          width: 310px;
        }
      }
    }
  }
}

@media screen and (max-width: 376px) {
  .sub-locations {

    &__items {
      width: 290px;
    }
  }

  .van {
    &__capacity {

      &__container {
  
        &__items {
          width: 290px;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .van {
    &__capacity {
      &__container {
        &__item {

          font-size: 13px;
  
          svg {
            width: 13px;
          }
  
          &__price {
            display: flex;
            gap: 0;
          }
        }
      }
    }

    &__info {
      display: flex;

      &__price {
        display: none;
        ;
      }

      &__name {
        font-size: 19px;
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 330px) {
  .van {
  
    &__main-imgs {
      width: 270px;
      height: 202.5px;
    }

    &__capacity {
      container {
        &__item {
          padding: 6px 11px;
        }
      }
    }

    &__info {
      &__imgs {
        height: 85px;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .sub-locations {

    &__items {
      width: 240px;
    }
  }

  .van {
    &__capacity {

      &__container {
  
        &__items {
          width: 240px;
        }
      }
    }
  }
}