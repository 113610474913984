@import 'src/style/mixin.scss';

.filter {
  &__title {
    @include font(16, $primary-light-black, 500);
    &::first-letter {
      text-transform: uppercase;
    }
    &__tip {
      margin-left: 5px;
      display: inline-block;
      @include font(12, $primary-light-black);
    }
  }
  &__review-list {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
  }
}
.filter-line {
  margin: 15px 0;
  padding: 0 18px;
  height: 1px;
  background-color: #f4f6fa;
}
.filter-actions {
  @include flex(center, 8, row, flex-end);
  margin-top: 20px;
  button {
    height: unset;
    padding: 6px 15px !important;
  }
  @media (max-width: 1261px) {
    justify-content: flex-start;
  }
}

.data-picker {
  @include flex(flex-start, 0, column, center);
  &__wrapper {
    @include flex(center, 0);
  }
  &__calender {
    position: relative;
    width: 130px;
  }
  &__clock {
    width: 95px;
  }
}
.filter {
  &__location {
    width: 160px;
    .MuiFormControl-root {
      width: 100%;
      // height: 23px;
    }
    .MuiOutlinedInput-root {
      min-height: unset;
      .MuiSelect-select {
        padding: 0 !important;
      }
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
      .MuiSvgIcon-root {
        visibility: hidden;
      }
    }
  }
  &__time {
    @include flex(center, 0, row, center);
  }
}
.text {
  @include font(14);
}
@media screen and (max-width: 1261px) {
  .data-picker {
    @include flex(flex-start, 0, column, center);
    .MuiOutlinedInput-root {
      padding: 0;
      .MuiOutlinedInput-input {
        padding: 0;
      }
      .MuiButtonBase-root,
      .MuiSvgIcon-root {
        display: none;
      }
      .MuiSelect-select {
        padding: 0 5px !important;
      }
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }

    &__calender {
      width: inherit;
    }
    &__clock {
      width: inherit;
    }
  }
  .filter {
    &__title {
      margin-bottom: 0;
    }
    &__list {
      display: flex;
      flex-direction: row;
      @media (max-width: 461px) {
        flex-direction: column;
      }
    }
    &__review-list {
      margin-bottom: 0;
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 30px;
      justify-content: center;
    }
    &__location {
      width: 155px;
    }
    &__time {
      @include flex(center, 5, row, center);
      @include bg();
      padding: 5px 10px;
      width: 100%;
      min-height: 70px;
    }
  }
}

@media screen and (max-width: 461px) {
  .filter {
    &__review-list {
      margin-bottom: 0;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
    }
  }
}
