@import 'src/style/mixin.scss';

@media screen and (max-width: 390px) {
  .payments-card {

    &__footer {
      align-items: flex-start;
      flex-direction: column;

      button {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 330px) {
  .payments-card {

    &__payment {
      gap: 5px;
    }
  }
}