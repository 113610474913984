@import 'src/style/mixin';

.driver {
  max-width: 500px;
  margin: 0 auto;
  padding: 50px 10px 0;
  &__wrapper {
    @include bg();
    width: 100%;
    padding: 40px;
  }
  &__title {
    @include font(28, $primary-dark-black, 600);
    margin-bottom: 30px;
    text-align: center;
  }
  &__description {
    margin-top: 10px;
    p {
      @include font(14, $primary-black, 500, 20);
    }
  }

  &__item {
    position: relative;
    margin-bottom: 20px;

    &__name {
      margin-bottom: 10px;
    }

    &__remove-icon {
      position: absolute;
      top: 30px;
      left: -30px;
      cursor: pointer;
    }
  }

  &__add-more {
    height: 30px !important;
    padding: 8px !important;
    background: #c1ff45 !important;
  }

}
@media screen and (max-width: 991px) {
  .driver {
    padding: 10px;
    width: 100%;
    &__wrapper {
      & > div.col-2 {
        grid-template-columns: 1fr;
      }
    }
  }
}
