@import 'src/style/mixin.scss';

.client-card {
  @include flex(flex-start, 0, column, space-between);

  &__container {
    @include flex(center, 10, row, center);

    a {
      height: 24px;

      svg {
        fill: rgb(125, 135, 156);
      }
    }
  }

  &__name {
    text-transform: capitalize;
  }
}