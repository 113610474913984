@import 'src/style/mixin.scss';

@media screen and (max-width: 400px) {
  .modal-delete {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;

    ;
    .col-2 {
      grid-template-columns: 1fr;
    }
  }
}