@import 'src/style/mixin.scss';

.note-card {

  &__header {
    @include flex(flex-end, 5, row, space-between);
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;

    &__title {
      @include flex(flex-start, 5, column, center);
  
      h4 {
        @include font(20, $primary-black, 700);
        margin-bottom: 0;
      }
  
      h6 {
        @include font(14, $primary-black, 500);
      }
    }
  }

  &__edit {
    cursor: pointer;
  }

  button {
    margin-top: 20px;
    margin-left: auto;

    &.Mui-disabled {
      background-color: rgba(225, 226, 234, 1) !important;
    }
  }
}