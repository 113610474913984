@import 'src/style/mixin.scss';

.order-cart {
  @include bg();
  @include flex(stretch, 20, column, center);
  // top: 4%;
  // right: 0;
  // position: sticky;
  width: 100%;
  height: min-content;
  padding: 30px;
  &__header {
    @include flex(flex-start, 0, row, space-between);
    @include font(32, $primary-dark-black);
    &__date {
      @include flex(flex-end, 4, column, center);
      @include font(13, $primary-dark-black, 500);
    }
    &__location {
      @include font(16, $primary-dark-black, 600, 15);
      text-decoration-line: underline !important;
    }

    &__sub-location {
      @include font(14, $primary-dark-black, 600, 15);
      text-decoration-line: underline !important;
    }

    &__date {
      font-size: 14px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: -0.02em;
    }
  }
  &__button {
    width: 100% !important;
  }
  &__wrapper {
    @include flex(flex-start, 20, column);
    border-bottom: 1px #e3e3ea solid;
  }
  &__total-price {
    display: flex;
    align-items: center;
    border-bottom: 1px #e3e3ea solid;
    padding-bottom: 16px;
    &__price {
      margin-left: auto;
      font-weight: 500 !important; 
    }
    &__title {
      font-weight: 500;
      font-size: 18px;
      color: #4B566B;
    }
  }
  &__wrapper-total {
    position: relative;
    &__info {
      @include flex(center, 13, column, center);
      &__price {
        font-weight: 700;
        font-size: 24px;
        line-height: 108.9%;
        color: $secondary-blue;
      }
      &__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 108.9%;
        color: $secondary-blue;
      }
      &__subtitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 108.9%;
        color: #4B566B;
      }
    }
    &__buttons {
      @include flex(center, 15, row, space-between);
      margin-top: 15px;
      button {
        width: 100%;
      }
      &.stripe {
        margin-top: 40px;
      }
    }
  }
  &_empty {
    @include flex(center, 10, column, center);
    margin: 25px 0;
    svg {
      height: 50px;
      width: 50px;
    }
    &__text {
      @include font(14, $primary-grey);
    }
  }
  &__item {
    width: 100%;
    display: grid;
    grid-template-columns: 42px auto 80px 20px;
    align-items: center;
    gap: 5px;
    &__img {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      @include img();
      img {
        border-radius: 5px;
      }
    }
    &:last-of-type {
      margin-bottom: 20px;
    }
    &__info {
      @include flex(flex-start, 4, column, center);
      &__title {
        @include font(14, $primary-dark-black, 600);
      }
      &__description {
        font-weight: 500 !important;
        @include font();
      }
    }
    &__price {
      @include font(14, $primary-dark-black, 600);
      text-align: right;
    }
    &__remove {
      @include flex();
      cursor: pointer;
      path {
        fill: gray;
      }
    }
  }
  &__go-to-pay-button {
    height: 25px !important;
    padding: 5px !important;
    margin-top: 20px !important;

    svg {
      height: 15px;
    }
  }
}
