@import 'src/style/mixin.scss';

.singleVan {
  padding: 50px;

  &__title-container {
    display: flex;
    margin-bottom: 50px;

    &__description {
      width: fit-content;
      font-size: 18px;
      color: #4b566b;
      font-weight: 600;
      line-height: 30px;
      padding-left: 10px;
      padding-right: 10px;
      border: 2px solid #ccc;
      border-radius: 5px;
      display: flex;
      align-items: flex-start;

      div {
        min-width: 210px;
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }

    button {
      width: fit-content;
      min-width: 150px;
      height: 50px !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  &__title {
    @include font(35, $primary-dark-black, 700);
    margin-bottom: 10px;
  }

  &__description {
    font-size: 21px;
    color: #4b566b;
    font-weight: 600;
    line-height: 30px;

    p {
      width: 70%;
      margin-right: auto;
    }
  }

  &__imgs {
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    display: flex;
    gap: 10px;
    &__bigImg {
      // grid-column: 1/4;
      // max-width: 100%;
      // margin-left: auto;
      // margin-right: auto;
      position: relative;
      max-width: 65%;
      @include bg(15, none, $primary-white, none);
      height: 600px;
      overflow: hidden;
      filter: drop-shadow(0 0 1px $primary-grey);

      &__open-gallery {
        position: absolute;
        top: 0;
        left: 30px;
        width: 94%;
        height: 100%;
        z-index: 99999;
        cursor: pointer;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }

    &__smallImgs {
      height: 600px;
      display: grid;
      align-content: space-between;
      gap: 10px;
//
      width: 100%;
//
      img {
        height: 295px;
        // width: 100%;
        border-radius: 20px;
        cursor: pointer;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__main-info {
    margin-top: 30px;

    h2 {
      @include font(30, $primary-dark-black, 600);
      margin-bottom: 10px;
    }
  }

  &__features {

    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    
    h3 {
      margin-bottom: 30px;
      padding-left: 10px;
    }

    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      &__checkIcon {
        width: 35px;
        height: 35px;
        margin-right: 20px;
      }

      &__title {
        font-size: 20px;
        color: #091b3d;
        font-weight: 600;
        line-height: 26px;
      }

      h4::first-letter {
        text-transform: uppercase;
      }
    }
  }

  &__extras {

    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 20px;

    h3 {
      margin-bottom: 30px;
      padding-left: 10px;
    }

    &__description {
      font-size: 17px;
      color: #4b566b;
      font-weight: 600;
      line-height: 30px;
      padding-left: 10px;
      margin-bottom: 20px;
    }

    &__container {
      padding-left: 10px;

      &__item {
        width: 30px;
        height: 30px;
        border-radius: 100%;

        img {
          border-radius: 5px;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      p {
        font-size: 20px;
        color: #091b3d;
        font-weight: 600;
        line-height: 26px;
        margin-bottom: 10px;
      }
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &__map {
    width: 70%;
    margin-left: auto;
    margin-right: auto;

    &__description {
      margin-top: 20px;
      background: #c1ff45;
      padding: 20px;
      border-radius: 10px;
    }

    h3 {
      margin-bottom: 30px;
      padding-left: 10px;
    }
  }

  &__availability {
    width: 70%;
    margin-left: auto;
    margin-right: auto;

    h3 {
      margin-bottom: 30px;
      padding-left: 10px;
    }

    &__formContainer {
      @include flex(flex-start, 20, row, center);
      align-items: flex-end;
      &__times {
        @include flex(center, 10, row, center);
      }

      &__locations {
        @include flex(flex-start, 20, row, center);
      }

      &__button {
        height: 50px !important;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }

    &__van {
      margin-top: 30px;
    }

    &__noVan {
      margin-top: 30px;
      color: #d24057;
    }
  }

  &__iconsContainer {

    position: relative;
    width: 35px;
    height: 35px;
    margin-right: 20px;

    &__circleIcon {
      position: absolute;
      fill: #c1ff45 !important
    }

    &__checkIcon {
      position: absolute;
      fill: #4b566b !important;
      top: 5px;
      left: 5px
    }
  }
}

.borderBottom {
  border-bottom: 2px solid #ccc;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

h3 {
  font-size: 25px;
  color: #091b3d;
  font-weight: 600;
}

.anchor {
  width: fit-content;
  display: block;
  margin-left: 100px;
  margin-bottom: auto;
  margin-top: auto;
  text-decoration: none;
}

.gap-icon {
  min-width: 12px;
  margin-right: 5px;
  margin-top: 7px;
}