@import 'src/style/mixin.scss';

.doc-item {
  width: 100%;
  @include flex(center, 10, row, space-between);

  &__container {
    @include flex(center, 10, row, flex-start);
  }
  
  &__icons {
    @include flex(center, 0, row, flex-start);

    &__icon {

      svg {
        cursor: default !important;
      }
    }
  }

  &__title {
    @include font(16, $primary-black, 700);
  }

  &__text {
    @include font(16, $primary-black, 400);
  }

  &__delete {
    height: 22px;
  }

  a {
    display: block;
    height: 24px;
  }

  // svg:hover path {
  //   stroke: $primary-black;
  // }
}