@import "src/style/mixin.scss";

@media screen and (max-width: 991px) {
  .order-cart {
    gap: 10px;
    padding: 15px;
    &__wrapper-total__buttons {
      @include flex(center, 10, column, center);
      flex-direction: column-reverse;
    }
  }
}

@media screen and (max-width: 360px) {
  .order-cart {
    &__header__location {
      text-wrap: balance;
      text-align: end;
    }
  }
}
