@import "src/style/mixin.scss";

.van {
  &__card {
    @include bg();
    width: 100%;
    min-height: 255px;
    padding: 20px;
    display: grid;
    grid-template-columns: 230px auto;
    gap: 0 25px;
    overflow: hidden;
    border: 1px solid #D8D9E1;
  }
  &__main-imgs {
    @include bg(6, none, $primary-white, none);
    @include flex(center, 0, row, center);
    width: 100%;
    height: 150px;
    overflow: hidden;
    filter: drop-shadow(0 0 1px $primary-grey);
    margin-bottom: 5px;
  }
  &__capacity {
    @include flex(center, 16, row);
    margin-bottom: 22px;
    &__title {
      @include font(12, $primary-light-black, 700);
      text-transform: uppercase;
    }
    &__container {
      position: relative;

      &__items {
        @include flex(center, 6, row);
        width: 347px;
        overflow: auto;
        outline: none;
        overflow-y: hidden;
        -ms-overflow-style: scroll;
        scrollbar-width: none;
        margin: 0 20px;
      }
      &__item {
        @include flex(center, 5, row, center);
        @include font(14, #2B3445, 500);
        @include bg(6, none, $primary-white);
        pointer-events: none;
        height: 26px;
        padding: 8px 13px;
        border: 1px solid $primary-light-black;
        white-space: nowrap;

        svg {
          height: 17px;
        }
  
        &__element {
          @include flex(center, 5, row, center);
        }
        path {
          fill: $primary-black;
        }
        &__black {
          color: #091b3d;
          font-weight: 600;
        }
        &__price {
          display: none;
          flex-direction: column;
        }
      }
      &__prev-button {
        top: 4px;
        left: 2px;
      }

      &__next-button {
          top: 4px;
          right: -4px;
      }
    }
  }
  &__info {
    display: grid;
    gap: 10px;
    grid-template-columns: auto 22px 83px;
    &__price {
      @include font(16, #2B3445, 500);
      text-align: end;
      &__description {
        @include font(12, #8891A4, 600);
      }
    }
    &__name {
      @include font(24, $primary-dark-black !important, 700);
      line-height: 26px;
      grid-column: 1/2;
      text-decoration: none;
      cursor: pointer;
    }
    &__extra {
      width: 100%;
      margin-bottom: 10px;
      @include flex(center, 15, row, flex-start);
      &__item {
        @include flex(center, 4);
        @include font(14, $primary-black, 600, 17);

        &__icons-container {
          position: relative;
          width: 20px;
          height: 20px;

          &__circleIcon {
            position: absolute;
            fill: #c1ff45 !important;
          }

          &__checkIcon {
            position: absolute;
            fill: #4b566b !important;
          }
        }
      }
    }
    &__description {
      @include font(14, $primary-light-black, 400, 18);
      margin: 12px 0 20px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      &_more {
        display: inline-block;
        height: 18px;
        margin-left: 3px;
        padding: 0 6px;
        // border-radius: 3px;
        cursor: pointer;
        @include font(14, $primary-black, 600);
        text-decoration: underline !important;
        // background-color: #c1ff45;
      }
    }
    &__imgs {
      @include flex(flex-start, 5, row, center);
      height: 72px;
      img {
        background-color: $primary-white;
        max-width: 190px;
        max-height: 100%;
        border-radius: 3px;
        @include bg(5);
        cursor: pointer;
      }
    }
    &__near-van {
      grid-column: 1/3;
      width: 100%;
      margin-top: 10px;
      padding: 5px 10px;
      text-align: center;
      border-radius: 5px;
      font-size: 15px;
      font-weight: 600;
      line-height: 21px;
      color: #2B3445;
      background: rgba(127, 137, 157, 0.2784313725);

      &__no-price {
        display: inline-flex;
        align-items: center;
        gap: 5px;

        span {
          margin-top: 7px;
        }
      }

      @media (max-width: 735px) {
        margin-top: 0;
      }
    }

    svg {
      color: #4b566b;
      cursor: pointer;
    }
  }

  &__order {
    display: flex;
    justify-content: space-between;
    &__container {
      @include flex(center, 28, row);
    }

    &__extras {
      @include flex(center, 28, row);
    }

    &__block {
      @include flex(flex-start, 8, column, flex-end);
    }
    &__host {
      @include flex(center, 10);
      &__container {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      &__title {
        @include font(12, $primary-light-black, 400);
        text-transform: uppercase;
      }
      &__name {
        @include font(16, $secondary-blue, 400);
      }
    }
    &__btn {
      height: 42px;
      width: 100%;
      text-transform: capitalize;
    }
    &__extra {
      display: flex;
      &__item {
        @include flex(center, 15);
        @include font(14, $primary-black, 500);

        &__icons-container {
          position: relative;
          width: 20px;
          height: 20px;

          &__circleIcon {
            fill: #c1ff45 !important;
          }

          &__checkIcon {
            position: absolute;
            fill: #4b566b !important;
            font-size: 0.6rem !important;
            left: 5px;
            top: 6px;
            stroke: #4b566b;
            stroke-width: 2;
          }
        }
      }

      &__delivery {
        width: 94px;
      }

      &__miles {
        max-width: 160px;
      }
    }
  }
}
.vans-gallery {
  @include flex(center, 10, column, center);
  @include bg(5, unset, transparent);
  margin: 150px auto 0;
  padding: 10px;
  width: 850px;
  height: 600px;
  @media (max-width: 850px) {
    width: auto;
  }
  @media (max-width: 500px) {
    height: 400px;
  }
  &__swiper1 {
    width: 100%;
    height: 100%;
  }
  &__swiper2 {
    width: 100%;
    height: 20%;
  }
}

.mobile-show {
 display: none;

 @media (max-width: 400px) {
  display: block;
}
}

.mobile-hide {
  display: block;

  @media (max-width: 400px) {
    display: none;
  }
}

.sub-locations {
  margin-top: 10px;
  position: relative;

  &__items {
    display: flex;
    gap: 10px;
  }
  &__item {
    width: fit-content;
    padding: 6px 13px;
    border: 1px solid #7D879C;
    border-radius: 6px;
    font-size: 12px;
    line-height: 12px;
    color: #2B3445;
    font-weight: 500;
    background-color: #f1f4fc;
    text-wrap: nowrap;
  }
  &__prev-button {
    top: 3px;
    left: 2px;
  }

  &__next-button {
    top: 3px;
    right: -4px;
  }
}

.swiper-button-next {
  right: 0px;
}
.swiper-button-prev {
  left: 0px;
}
.swiper-button-next::after, 
.swiper-button-prev::after {
  font-size: larger;
}

.user-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}