
.scroll-item {
    
    &__button {
        position: absolute;
        fill: #2b3445bd !important;
        cursor: pointer;
    }

    &__inactive {
        fill: rgb(43 52 69 / 23%) !important;
    }

    &__invisible {
        display: none !important;
    }
}