@import 'src/style/mixin.scss';

.result-page {
  @include flex(center, 20, column, center);
  margin: 0 auto;
  padding-top: 100px;
  &__img {
    width: 200px;
  }
  &__main {
    div {
      display: flex;
      flex-direction: column;
      text-align: center;
      p {
        display: inline-block;
        &:first-of-type {
          @include font(22, $primary-dark-black);
          letter-spacing: 0;
          margin-bottom: 0;
        }
        &:last-of-type {
          @include font(12, $primary-light-black);
        }

        text-align: center;
      }
    }
    @include flex(center, 15, column, center);
    max-width: 450px;
    p {
      display: inline-block;
      &:first-of-type {
        margin-bottom: 20px;
      }
      @include font(22, $primary-dark-black);
      letter-spacing: 0.5px;

      text-align: center;
    }
  }

  &__payment {
    max-width: 700px;

    &__text {
      line-height: 30px !important;

      span {
        cursor: pointer;
        text-decoration: underline; 
        color: #4b566b;
      }
    }
  }

  &__driver-invite {
    max-width: 470px;
    gap: 20px;
    margin-bottom: 20px;

    &__text {
      line-height: 35px !important;
    }

    &__title {
      margin-bottom: 0 !important;
    }
  }

  &__upload {
    max-width: fit-content;

    &__title {
      margin-bottom: 10px !important;
    }

    ul {
      list-style: none;
      li::before {
        content: "\2022";
        color: rgb(75, 86, 107);
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        margin-bottom: 5px;
      }
      li {
        margin-left: 20px;
        margin-bottom: 10px;
        p {
          font-size: 20px;
          letter-spacing: 0;
          margin-bottom: 0px !important;
        }
        aside {
          @include font(12, $primary-light-black);
        }
      }
    }
  }

  &_link {
    @include flex(center, 50, row, space-between);
    // a {
    //   @include font(20, $primary-blue, 500);
    // }
    button {
      min-width: 172px;
      a {
        text-decoration: none !important;
        color: #4b566b;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .result-page {
    padding-top: 40px;
    &__main {
      padding: 0 20px;
      width: 100%;
      p {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .result-page {
    &_link {
      display: grid;
      gap: 20px;
    }
  }
}

@media screen and (max-width: 400px) {
  .result-page {
    &__upload {
      p,a {
        font-size: 15px;
      }
      li {
        margin-bottom: 0px !important;
        p {
          font-size: 15px !important;
        }
        aside {
          margin-top: -5px;
        }
      }
    }
  }
}
