@import 'src/style/mixin.scss';

@media screen and (max-width: 400px) {
  .articles-card {

    &__van {

      &__price {
        width: auto;
        border: none;

        h6 {
          font-size: 18px;
        }
      }
    }
  }
}