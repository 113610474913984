@import 'src/style/mixin.scss';

.cancel-booking {
  max-width: 550px;
  padding-top: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 300px;
  
  &__description {
    font-size: 18px;
    color: #4b566b;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 30px;
  }
  &__form-container {
    display: grid;
    gap: 30px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
  }

  &__agreement {
    font-size: 15px;
    color: #7d879c;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 30px;
  }

  button {
    width: fit-content !important;
    min-width: 140px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 550px) {
  .cancel-booking {
    margin-left: 50px;
    margin-right: 50px;
  }
}

@media screen and (max-width: 380px) {
  .cancel-booking {
    margin-left: 10px;
    margin-right: 10px;
  }
}