@import 'src/style/mixin.scss';

.smallHeader {
  @include bg(0);
  @include font();
  top: 0;
  position: fixed;
  border: none;
  width: 100%;
  z-index: 100;
  transition: all 0.4s ease;
  &__wrapper {
    width: 100%;
    margin: 0 auto;
    background-color: #d8e3e2;

    &_sup {
      align-items: center;
      justify-items: center;
      padding: 0;

      .logo {
        width: 100%;
        text-align: center;
        @include flex(center, 0, column, flex-start);
        @include font(14, $primary-black, 400);

        img {
          width: inherit;
        }
      }
    }
  }
  &__container {
    padding: 5px 10px;
    display: flex;
    gap: 100px;

    a {
      text-decoration: none;
    }

    &__links {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__link {
        cursor: pointer;
        @include font(18, $primary-black, 700);
        text-decoration: none;
      }

      &__logout {
        height: 24px;
        cursor: pointer;
      }

      &__menu-logout {
        width: 100%;
        @include flex(center, 0, row, space-between);
      }
    }

    &__button-container {
      display: flex;
      align-items: center;

      &__button {
        width: fit-content;
        min-width: 140px !important;
        height: 50px !important;
        margin-left: auto !important;
        text-wrap: nowrap;
      }
    }
  }

  &__burger {
    margin-left: auto;
  }
}
