@import 'src/style/mixin.scss';

.cancel-modal {
  width: 400px;
  margin: 150px auto;
  padding: 40px;

  &__text {
    @include font(20, $primary-black, 500, 30);
  }

  &__policy {
    @include flex(center, 5, row, flex-start);
    @include font(14, $primary-black, 500);
    margin-top: 20px;
  }

  &__buttons {
    @include flex(center, 10, row, space-between);
    margin-top: 20px;
          
    button {
      width: 100%;
    }
  }
}