@import 'src/style/mixin';

.contract {
  max-width: 900px;
  margin: 0 auto;
  padding: 10px;
  &__frame {
    iframe {
      @include bg();
      width: 100%;
      height: 70vh;
    }
  }
}
@media screen and (max-width: 991px) {
  .contract {
    width: 100%;
  }
}
