@import "src/style/mixin.scss";

.no-result {
  @include flex(center, 5, column, center);
  text-align: center;

  &__container {
    @include flex(center, 14, row, center);
    margin: 15px 0;
    padding: 5px 12px;
    background-color: $primary-green;
    border-radius: 7px;

    span {
      @include font(16, $primary-black, 600);
    }
  }

  img {
    @include bg();
    margin-bottom: 10px;
    filter: drop-shadow(0 0 10px $primary-white);
  }

  a {
    text-decoration: underline !important;
    margin-left: 5px;
    display: inline-block;
    @include font(16, $primary-black);
  }

  div {
    @include font(16);
    &:first-of-type {
      @include font(24, $primary-black, 700);
    }
  }
}
