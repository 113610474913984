@import "src/style/mixin.scss";

@media screen and (min-width: 1900px) {
    .singleVan {

        &__imgs {
            width: 80%;
            margin-left: auto;
            margin-right: auto;

            &__bigImg {
                height: 700px;
            }
            &__smallImgs {
                height: 700px;

                img {
                    height: 345px;
                }
            }
        }
    }
}

@media screen and (min-width: 1500px) {
    .singleVan {

        &__imgs {

            &__bigImg {
                height: 700px;
            }
            &__smallImgs {
                height: 700px;

                img {
                    height: 345px;
                }
            }
        }

        &__availability {
            &__van {
                margin-left: -100px;
                margin-right: -100px;
            }
        }
      
    }
}

@media screen and (max-width: 1520px) {
    .singleVan {

        &__availability {

            &__formContainer {
                display: grid;
            }
        }  
    }
}

@media screen and (max-width: 1410px) {
    .singleVan {

        &__container {
            width: 100%;
        }

        &__imgs {

            &__bigImg {
                height: 550px;

                &__open-gallery {
                    left: 25px;
                }
            }
            &__smallImgs {
                height: 550px;

                img {
                    height: 270px;
                }
            }
        }

        &__availability {
            &__van {
                margin-left: -100px;
                margin-right: -100px;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .singleVan {

        &__imgs {

            &__bigImg {
                height: 500px;

                &__open-gallery {
                    left: 20px;
                }
            }
            &__smallImgs {
                height: 500px;

                img {
                    height: 245px;
                }
            }
        }
    }
}

@media screen and (max-width: 1260px) {
    .singleVan {
        margin-top: 80px;
    }
}

@media screen and (max-width: 1140px) {
    .singleVan {

        &__title {
            @include font(30, $primary-dark-black, 700);
        }

        &__title-container{
            &__description {
                font-size: 16px;
            }
        }

        &__main-info {
            h2 {
                font-size: 25px;
                margin-bottom: 0;
            }

            h3 {
                font-size: 20px;
            }
        }
            
        &__features {
            h3 {
                font-size: 20px;
            }

            &__item {
                h4 {
                font-size: 16px;
                line-height: 20px;
            }
            }
        }

        &__extras {
            h3 {
                font-size: 20px;
            }

            &__container {
                p {
                font-size: 16px;
                line-height: 20px;
            }
            }
        }

        &__imgs {

            &__bigImg {
                height: 450px;
            }
            &__smallImgs {
                height: 450px;

                img {
                    height: 220px;
                }
            }
        }
    }

    .location {
        width: 210px;
    }
}

@media screen and (max-width: 1080px) {
    .singleVan {
        &__imgs {

            &__bigImg {
                height: 400px;
            }
            &__smallImgs {
                height: 400px;

                img {
                    height: 195px;
                }
            }
        }

        &__availability {
            &__van {
                margin-left: -160px;
                margin-right: -160px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .singleVan {
        margin-top: 40px;
       
        &__imgs {

            &__bigImg {

                &__open-gallery {
                    left: 25px;
                    width: 91%;
                }
            }
        }
    }
}

@media screen and (max-width: 930px) {
    .singleVan {

        &__container { 
            p {
                font-size: 17px;
            }
        }

        &__features {
            &__item {
               
                &__checkIcon {
                    width: 40px;
                    height: 40px;
                }
                &__title {
                    font-size: 17px;
                }
            }
        }

        &__imgs {
            display: block;
            &__bigImg {
                max-width: 80%;
                height: 500px;
                margin-left: auto;
                margin-right: auto;
            }

            &__smallImgs {
                margin-top: 20px;
                align-items: center;
                justify-content: space-between;
                display: flex;
                max-width: 80%;
                margin-left: auto;
                margin-right: auto;
                height: auto;

                // img {
                //     width: 49%;
                // }
    
            }
        }

        &__map {
            width: 80%;
        }

        &__availability {
            width: 80%;
        }
    }
}

@media screen and (max-width: 885px) {
    .singleVan {

        &__title {
            font-size: 25px;
        }

        &__main-info {
            h2 {
                font-size: 23px;
                margin-bottom: 0;
            }

            h3 {
                font-size: 21px;
            }
        }

        &__availability {
            &__van {
                margin-left: -130px;
                margin-right: -130px;
            }
        }

        .anchor {
            margin-left: 30px;
        }
    }
}

@media screen and (max-width: 780px) {
    .singleVan {

        &__imgs {

            &__bigImg { 

                &__open-gallery {
                    width: 88%;
                }
            }
        }

        &__features {
            &__container {
                display: block;
            }
        }

        &__container {
            display: block;
        }

        &__extras {
            margin-bottom: 50px;
            width: 400px;
            margin-left: auto;
            margin-right: auto;

            h3 {
                font-size: 23px;
            }

            &__container {
                p {
                font-size: 20px;
            }
            }
        }

        &__features {
            width: 400px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 50px;

            h3 {
                margin-bottom: 10px;
                font-size: 23px;
            }

            &__item {
                h4 {
                    font-size: 20px;
                }
            }
        }

        &__title-container {
            display: block;
        }

        &__description {

            p {
                width: 100%;
            }

        }
    }

    .anchor {
        margin-left: auto !important;
        margin-right: auto;
        margin-top: 20px;
    }
}

@media screen and (max-width: 660px) {
    .singleVan {
        &__availability {
            &__van {
                margin-left: -100px;
                margin-right: -100px;
            }
        }

        &__imgs {

            &__smallImgs {

                img {
                    height: 150px;
                }

            }
        }
    }
}

@media screen and (max-width: 600px) {
    .singleVan {
       
        &__imgs {

            &__bigImg {
                height: 400px;

                &__open-gallery {
                    width: 85%;
                }
            }

            &__smallImgs {

                img {
                    height: 120px;
                }

            }
        }

        &__description {
    
            p {
                width: 100%;
                font-size: 20px;
            }
        }

        &__map {
            width: 100%;
            
            &__description {
                font-size: 20px;
                margin-top: 20px;
            }

            h3 {
                margin-bottom: 20px;
            }
        }

        &__availability {
            width: 100%;
        }

        &__main-info {
            h2 {
                font-size: 20px;
            }
            h3 {
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .singleVan {

        &__title {
            @include font(25, $primary-dark-black, 700);
            margin-bottom: 20px;
        }

        &__description {
            font-size: 18px;
            margin-bottom: 20px !important;

            p{
                font-size: 18px;
            }
        }

        &__main-info {
            margin-top: 30px;

            h2 {
                @include font(18, $primary-dark-black, 600);
            }

            h3 {
                font-size: 15px;
            }
        }

        &__imgs {

            &__bigImg {
                max-width: 100%;
                height: 300px;
            }

            &__smallImgs {
                max-width: 100%;
            }
        }

        &__features {
            width: 300px;
            
            h3 {
                font-size: 20px;
            }

            &__item {
    
                &__checkIcon {
                    width: 40px;
                    height: 40px;
                    margin-right: 20px;
                }
    
                &__title {
                    font-size: 19px;
                    color: #091b3d;
                }

            }
        }

        &__extras {
            width: 300px;
            margin-bottom: 30px;

            h3 {
                font-size: 20px;
            }
        }

        &__map {

            h3 {
                font-size: 20px;
            }
        }

        &__availability {
            &__formContainer {
                &__times {
                    display: grid;
                }
                &__locations {
                    display: grid;
                }
            }

            &__van {
                margin-left: -80px;
                margin-right: -80px;
            }

            h3 {
                font-size: 20px;
            }
        }
    }
    .borderBottom {
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 390px) {
    .singleVan {
        padding: 20px;
        margin-top: 80px;

        &__description {
            font-size: 16px;
            line-height: 20px;

        }

        &__extras {
            width: auto;
            p {
                font-size: 15px;
            }
        }

        &__features {
            width: auto;
            &__item {
                margin-bottom: 0;
                
                &__checkIcon {
                    width: 40px;
                    height: 40px;
                }
    
                &__title {
                    font-size: 15px;
                    font-weight: 600;
                }

                h4 {
                    font-size: 15px;
                }
            }
        }

        &__availability {
            &__van {
                margin-left: -60px;
                margin-right: -60px;
            }
        }

        &__imgs {
            &__smallImgs {
    
                img {
                    height: 110px;
                }
            }
        }
    }
    .borderBottom {
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 340px) {
    .singleVan {

        &__main-info {
            h3 {
                font-size: 13px;
            }
        }

        &__imgs {

            &__bigImg {

                &__open-gallery {
                    width: 83%;
                }
            }
        }

    }
}