@import "src/style/mixin.scss";

.order {
  max-width: 1440px;
  margin: 0 auto;
  padding: 24px 0;
  &__header {
    position: relative;
    display: grid;
    grid-template-columns: 100px 300px 700px;
    gap: 10px;
    margin-bottom: 20px;
    a {
      @include flex(center, 0, column, center);
      width: 100%;
    }
  }
  &__wrapper {
    display: grid;
    grid-template-columns: auto 450px;
    gap: 20px;
    min-height: 34vh;
  }
}

.extra-card {
  @include bg();
  display: grid;
  grid-template-columns: 60px auto 100px;
  gap: 20px;
  padding: 15px;
  width: 100%;
  &__logo {
    width: 60px;
    height: 60px;
    @include img();
  }
  &__info {
    @include flex(flex-start, 7, column);
    &__name {
      width: 100%;
      @include font(18, $primary-black, 600);
    }
    &__title {
      @include font();
    }
    &__description {
      @include font(13, $primary-light-black, 400, 19);
    }
  }
  &__price {
    @include font(18, $primary-dark-black, 600);
  }
  &__action {
    @include flex(flex-end, 10, column, space-between);
    &__button {
      height: 40px;
      width: 100%;
    }
  }
}
.order-payment {
  @include flex(flex-start, 20, column);
  padding: 40px;
  &__container {
    margin-right: auto;
    margin-left: auto;

    &__cart-container {
      max-width: 500px;
    }

    &__payment-container {
      margin: 20px;
      line-height: 30px;
    }
  }
  &__price-off {
    @include font(17, $primary-black, 500, 22);
    @include flex(stretch, 5, column);
    @include bg();
    max-width: 480px;
    justify-content: space-between;
    padding: 20px;
    a {
      display: inline-block;
      color: $primary-blue;
      text-decoration: underline;
      margin: 0 3px;
    }
  }
  &__voucher {
    position: relative;
    @include font();
  }
  &__option {
    .MuiFormControlLabel-root {
      height: 36px;
      .MuiTypography-root {
        @include font(16);
      }
    }
  }

  &__payment {
    display: grid;
    gap: 10px;

    &__back-button {
      width: 100%;
    }
  }

  &__quote {
    @include flex(center, 10, row, space-between);
    padding: 25px;
    border: 0.5px solid rgba(203, 204, 214, 1);
    border-radius: 7px;

    h5 {
      @include font(18, $primary-black, 700);
    }

    h6 {
      @include font(14, $primary-black, 500);
    }
  }
}
