@import "src/style/mixin.scss";

@media screen and (max-width: 991px) {
  .footer {
    margin-top: 0;
    padding: 20px 15px;
    &__section {
      width: 100%;
      grid-template-columns: repeat(3, 1fr);
      gap: 15px 10px;
      .footer__section-category {
        @include flex(flex-start, 6, column, flex-start);
        &__title {
          @include font(17, $primary-white, 500, 20);
        }
        &__item {
          font-size: 15px;
          line-height: 15px;
        }
      }
    }
    &__section-end {
      @include flex(center, 10, column, center);
      grid-column: 1/2;
      &__items {
        order: 1;
        @include flex(center, 3, row, center);
        a {
          display: inline-block;
          border-right: 1px solid $primary-black;
          padding-right: 5px;
          &:last-child {
            border-right: unset;
          }
          &:hover {
            color: $primary-white;
          }
        }
      }
    }
  }
}
