@import 'src/style/mixin.scss';

.drivers-card {
  @include flex(flex-start, 5, column, space-between);
  
  &__contract {
    width: 100%;
    @include flex(flex-start, 10, row, space-between);
    margin-bottom: 20px;
    
    &__title {
      @include flex(flex-start, 0, column, center);

      &__container {
        max-height: 25px;
        @include flex(flex-start, 5, row, center);
      }

      h6 {
        @include font(14, $primary-black, 500);
      }
    }

    button {
      padding: 6px 8px !important;
      border: 1px solid rgba(225, 226, 234, 1);
      background-color: rgba(250, 251, 254, 1) !important;
      @include font(12, $secondary-blue, 700);
    }
  }

  &__header {
    @include flex(center, 10, row, flex-start);
    
    span {
      @include font(20, $primary-black, 500);
    }
  }

  &__columns {
    @include flex(center, 5, row, space-between);
  }

  &__column {
    @include flex(flex-start, 10, column, center);
    margin-top: 10px;

    &__title {
      @include font(15, $primary-black, 700);
    }

    &__data {
      height: 36px;
      @include flex(center, 0, row, center);

      &__action {
        height: 16px;
        margin-left: auto;
        display: flex ;
        align-items: center;
        cursor: pointer;

        & > svg, div {
          height: 22px;
          color: $primary-black
        }
      }

      h6 {
        @include font(15, $primary-black, 300);
      }
    }

    &.actions {
      margin-top: 35px;
    }
  }

  &__invite {
    width: 100%;
    margin-top: 20px;

    button {
      width: 100%;
      padding: 6px 8px !important;
      border: 1px solid rgba(75, 86, 107, 1);
      background-color: rgba(255, 255, 255, 1) !important;
    }
  }

  h4 {
    @include font(20, $primary-black, 700);
    margin-bottom: 0;
  }
}