@import 'src/style/mixin.scss';

.edit-dates-modal {
  width: 550px;
  margin: 85px auto;
  padding: 50px;

  &__new-price {
    margin-top: 20px;
    text-align: center;
    @include font(18, $primary-red, 700);
  }
  
  &__buttons {
    margin-top: 30px
  }
}

@media screen and (max-width: 480px) {
  .edit-dates-modal {
    width: auto;
    max-width: 300px;
    padding: 30px;

    .col-2 {
      grid-template-columns: 1fr;
    }
  }
}