@import 'src/style/mixin.scss';

.quote {
  min-height: 100vh;
  background-color: rgba(250, 251, 254, 1);

  &__card {
    max-width: 470px;
    margin: 50px auto;
    padding: 25px;
    border-radius: 10px;
    background-color: $primary-white;

    &__top {
      @include flex(center, 10, column, center);
      text-align: center;
      margin-bottom: 25px;
      padding-bottom: 40px;
      border-bottom: 1.5px dashed rgba(216, 217, 225, 1);

      &__icon {
        position: relative;
        width: 55px;
        height: 55px;

        &__circleIcon {
          font-size: 55px !important;
          fill: #c1ff45 !important;
        }

        &__checkIcon {
          position: absolute;
          fill: #4b566b !important;
          font-size: 40px !important;
          left: 7px;
          top: 8px;
          stroke: #4b566b;
          stroke-width: 2;
        }
      }

      h3 {
        @include font(32, $primary-black, 700);
      }

      p {
        @include font(16, $primary-light-black, 500, 22);
      }
    }

    &__bottom {
      @include flex(flex-start, 10, column, center);

      &__item {
        padding: 20px;
        border: 1px solid rgba(216, 217, 225, 1);
        border-radius: 10px;

        h6 {
          margin-bottom: 10px;
          @include font(16, $primary-black, 700);
        }

        p {
          @include font(16, $primary-light-black, 400);
        }
      }

      h5 {
        margin-bottom: 10px;
        @include font(20, $primary-black, 700);
      }
    }

    &__buttons {
      margin-top: 50px;
      // @include flex(center, 10, row, space-between);
      @include flex(center, 10, row, flex-end);
    }
  }

  &__header {
    @include flex(center, 0, row, center);
    padding: 20px;
  }
}
