@import 'src/style/mixin.scss';

.modal-delete {
  width: 390px;
  height: inherit;
  position: relative;
  padding: 40px;
  margin: 160px auto;
  background: $primary-white;
  border-radius: 10px;

  &__container {
    @include flex(center, 8, row, flex-start);
  }

  &__icon {
    width: 50px;
    height: 50px;
    @include flex(center, 0, row, flex-start);
    fill: #4b566b;
  }

  &__text {
    @include font(17, $primary-light-black, 500, 22);
  }

  &__buttons {
    margin-top: 30px;
  }

  &:focus-visible {
    outline: none
  }
}