@import 'src/style/mixin.scss';

@media screen and (max-width: 820px) {
  .client-info-modal {
    width: auto;
    max-width: 500px;

    &__container {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}

@media screen and (max-width: 530px) {
  .client-info-modal {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 450px) {
  .client-info-modal {
    padding: 30px;

    .col-2 {
      grid-template-columns: 1fr;
    }
  }
}