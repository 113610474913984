.main {
  width: 1240px;
  margin: 24px auto 0;
  display: grid;
  min-height: 78vh;
  grid-template-columns: 270px repeat(3, 1fr);
  gap: 24px;
}

@media screen and (max-width: 1261px) {
  .main {
    display: block;
    width: 100vw;
    margin: 90px auto 0;
  }
}

@media screen and (max-width: 991px) {
  .main {
    display: block;
    width: 100vw;
    margin: 170px auto 0;
    &.mobile {
      margin-top: 170px;
    }
  }
}
