@import 'src/style/mixin.scss';

.documents-card {
  @include flex(flex-start, 5, column, space-between);

  &__container {
    @include flex(flex-start, 10, column, center);
  }

  &__footer {
    width: 100%;
    @include flex(center, 10, row, space-between);
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #ccc;
    
    &__container {
      @include flex(center, 7, row, center);

      span {
        @include font(16, $primary-black, 700);
      }
    }

    button {
      padding: 0 15px;
      border: none;
      background-color: rgba(25, 118, 210, 1) !important;
      
      a {
        color: $primary-white !important;
      }

      svg {
        margin-right: 10px;
        font-size: 1.2em;
        fill: $primary-white !important;
      }
    }
  }
}