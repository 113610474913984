@import "src/style/mixin.scss";

.header {
  &__wrapper {
    &_sup {
      .header__container {
        &__mobile-filters {
         display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1261px) {
  .header {
    top: 0;
    position: fixed;
    &__wrapper {
      width: 100%;
      &.hide {
        .header__wrapper_sup {
          .general-filter {
            transition: 0.2s all;
            padding: 0 10px;
            height: 0;
            opacity: 0;
          }
        }
      }
      &_sup {
        @include flex(center, 0, column, center);
        padding: 0;
        .logo {
          @include flex(center);
          width: 60px;
          img {
            width: inherit;
          }
        }
        .header__icon-set {
          display: none;
        }
        .header__mobile_icon-set {
          @include flex(center, 15, row, center);
        }
        .header__container {
          @include flex(center, 0, row, space-between);
          padding: 5px 10px;
          width: 100%;
          box-shadow: 0 0 2px $primary-grey;
          z-index: 100;
          overflow: hidden;
          background-color: $secondary-grey;
          a {
            width: 100px;
          }
          &__mobile-filters {
            @include flex(center, 8, row, center);
            &:first-child {
              gap: 2px;
            }
            .hamburger-lines {
              @include flex(center, 6, column, center);
              height: 25px;
              width: 24px;
              .line {
                display: block;
                height: 2px;
                width: 100%;
                border-radius: 10px;
                background: $primary-black;
              }
              .line1 {
                transform-origin: 0% 0%;
                transition: transform 0.4s ease-in-out;
              }
              .line2 {
                transition: transform 0.2s ease-in-out;
              }
              .line3 {
                transform-origin: 0% 100%;
                transition: transform 0.4s ease-in-out;
              }
              &.active {
                .line1 {
                  transform: rotate(45deg);
                }
                .line2 {
                  transform: scaleY(0);
                }
                .line3 {
                  transform: rotate(-45deg);
                }
              }
            }
          }
        }
      }
    }
    .dash {
      display: none;
    }
  }
  .filter-aside {
    margin: 30px auto;
    width: 500px !important;
    grid-column: unset !important;
    padding: 50px;
  }
}

@media screen and (max-width: 561px) {
  .filter-aside {
    width: 400px !important;
  }
}

@media screen and (max-width: 461px) {
  .filter-aside {
    width: 300px !important;
    padding: 30px;
  }
}