@import 'src/style/mixin.scss';

.vanModal {
  padding: 50px;
  
  &__top-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -30px;
    margin-bottom: 20px;

    &__container {
      @include flex(center, 30, row, center);
    }

    a {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 18px;
      font-weight: 600;
      color: #4b566b;
    }
    svg {
      color: #4b566b;
      cursor: pointer;
    }
  }

  &__title-container {
    display: flex;
    margin-bottom: 20px;

    &__description {
      width: fit-content;
      font-size: 18px;
      color: #4b566b;
      font-weight: 600;
      line-height: 30px;
      padding-left: 10px;
      padding-right: 10px;
      border: 2px solid #ccc;
      border-radius: 5px;
      display: flex;
      align-items: flex-start;

      div {
        min-width: 210px;
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }

    button {
      width: fit-content;
      min-width: 150px;
      height: 50px !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  &__title {
    @include font(25, $primary-dark-black, 700);
    margin-bottom: 10px;
  }

  &__description {
    font-size: 18px;
    color: #4b566b;
    font-weight: 600;
    line-height: 30px;
  }

  &__imgs {
    display: flex;
    gap: 10px;

    &__bigImg {
      max-width: 65%;
      @include bg(15, none, $primary-white, none);
      height: 300px;
      overflow: hidden;
      filter: drop-shadow(0 0 1px $primary-grey);

      &__open-gallery {
        position: absolute;
        top: 0;
        left: 30px;
        width: 87%;
        height: 100%;
        z-index: 99999;
        cursor: pointer;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        cursor: pointer;
        border-radius: 10px;
      }
    }

    &__smallImgs {
      height: 300px;
      display: grid;
      align-content: space-between;
      gap: 10px;
      width: 100%;

      img {
        height: 150px;
        border-radius: 10px;
        cursor: pointer;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__main-info {
    margin-top: 30px;

    h3 {
      font-size: 18px;
    }
  }

  &__features {

    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    
    h3 {
      font-size: 18px;
      margin-bottom: 20px;
      padding-left: 10px;
    }

    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      &__checkIcon {
        width: 35px;
        height: 35px;
        margin-right: 20px;
      }

      &__title {
        font-size: 15px;
        color: #091b3d;
        font-weight: 600;
        line-height: 18px;
      }

      h4::first-letter {
        text-transform: uppercase;
      }
    }
  }

  &__extras {

    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 10px;

    h3 {
      font-size: 18px;
      margin-bottom: 20px;
      padding-left: 10px;
      line-height: 20px;
    }

    &__description {
      font-size: 17px;
      color: #4b566b;
      font-weight: 600;
      line-height: 30px;
      padding-left: 10px;
      margin-bottom: 20px;
    }

    &__container {
      padding-left: 10px;

      &__item {
        width: 30px;
        height: 30px;
        border-radius: 100%;
    
        img {
          border-radius: 5px;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      &__miles {
        max-width: 160px
      }

      p {
        font-size: 15px;
        color: #091b3d;
        font-weight: 600;
        line-height: 18px;
      }
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &__map {

    &__description {
      margin-top: 20px;
      margin-bottom: 50px;
      background: #c1ff45;
      padding: 20px;
      border-radius: 10px;
    }

    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  &__availability {
    width: 70%;
    margin-left: auto;
    margin-right: auto;

    h3 {
      font-size: 18px;
      margin-bottom: 30px;
      padding-left: 10px;
    }

    &__formContainer {
      @include flex(flex-start, 20, row, center);
      align-items: flex-end;
      &__times {
        @include flex(center, 10, row, center);
      }

      &__locations {
        @include flex(flex-start, 20, row, center);
      }

      &__button {
        height: 50px !important;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }

    &__van {
      margin-top: 30px;
    }

    &__noVan {
      margin-top: 30px;
      color: #d24057;
    }
  }

  &__iconsContainer {

    position: relative;
    width: 25px;
    height: 25px;
    margin-right: 10px;

    &__circleIcon {
      position: absolute;
      fill: #c1ff45 !important
    }

    &__checkIcon {
      position: absolute;
      fill: #4b566b !important;
      top: 2px;
      left: 2px
    }
  }

    &__footer {
      width: 100%;
      background-color: #fff;
      bottom: 0;
      position: fixed;
      padding: 12px;
      margin-left: -50px;
      background-color: #d8e3e2;
      -webkit-box-shadow: 0px -5px 5px -5px #0b0b0c6e;
      -moz-box-shadow: 0px -5px 5px -5px #0b0b0c6e;
      box-shadow: 0px -5px 5px -5px #0b0b0c6e;
      animation-iteration-count: 1;
      animation-duration: 2s;
      animation-name: slidegdz;
      animation-fill-mode: forwards;
  
      @keyframes slidegdz {
        0% { width: 10%; }
        100% { width: 80%; }
      }
  
      button {
        margin-left: 590px;
        opacity: 0;
        transition: 1s;
        animation: show 3s 1;
        animation-fill-mode: forwards;
        animation-delay: 1s;
  
        @keyframes show {
          0% { opacity: 0; }
          100% { opacity: 1; }
        }
      }
    }
  }

.borderBottom {
  border-bottom: 2px solid #ccc;
  padding-bottom: 30px !important;
  margin-bottom: 30px !important;
}

h2 {
  @include font(22, $primary-dark-black, 600);
}

h3 {
  font-size: 18px;
  color: #091b3d;
  font-weight: 600;
}

.anchor {
  width: fit-content;
  display: block;
  margin-left: 100px;
  margin-bottom: auto;
  margin-top: auto;
  text-decoration: none;
}
