@import "src/style/mixin.scss";

.footer {
  position: static;
  bottom: 0;
  margin-top: 25px;
  width: 100%;
  padding: 20px 0;
  background: #283a97;
  &__wrapper {
    max-width: 1240px;
    margin: 32px auto;
    @include font(16, $primary-white, 400, 20);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    @media (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: 30px;
    }
  }
  &__section {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 20px auto;
    gap: 15px 0;
    &.two-column {
      grid-template-columns: repeat(2, 1fr);
      padding-left: 20px;
      .footer__section-title {
        grid-column: 1/3;
      }
      @media (max-width: 991px) {
        padding-left: 0;
      }
      @media (max-width: 485px) {
        grid-template-columns: 1fr;
        .footer__section-title {
          grid-column: 1/2;
        }
      }
    }
    &.locations {
      @media (max-width: 991px) {
        grid-template-columns: repeat(4, 1fr);
      }
      @media (max-width: 485px) {
        grid-template-columns: repeat(2, 1fr);
        .footer__section-title {
          grid-column: 1/3;
        }
      }
    }
    .footer__section-title {
      @include flex(center, 6, row, flex-start);
      @include font(24, $primary-grey, normal);
      grid-column: 1/5;
    }
    .footer__section-category {
      @include flex(flex-start, 3, column, flex-start);
      &.additional {
        margin-top: -55px;
        @media (max-width: 485px) {
          margin-top: 0;
        }
      }
      &__title {
        @include font(18, $primary-white, bold, 20);
        margin-top: 20px;
        margin-bottom: 10px;
      }
      &__item {
        color: #acb6c9;
        cursor: pointer;
        text-decoration: none;
        &:hover,
        &:active,
        &:focus {
          transition: 0.3s all;
          color: $primary-white;
        }
      }
      div.footer__section-category__item {
        color: #acb6c9;
        cursor: auto;
      }
    }
  }
  &__section-end {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid #7d879c;
    @include flex(center, 0, row, space-between);
    @include font(15, #acb6c9);
    grid-column: 1/3;
    text-align: center;
    &__items {
      @include flex(center, 10, row, center);
      a {
        @include font(15, #acb6c9);
        display: inline-block;
        border-right: 1px solid #acb6c9;
        padding-right: 10px;
        &:last-child {
          border-right: unset;
        }
        &:hover {
          color: $primary-white;
        }
      }
    }
  }
}
