@import "src/style/mixin.scss";

.multi-upload {
  &__title {
    grid-column: 1/3;
    @include font(15, $primary-black, 500, 10);
    margin-top: 10px;
  }
  &__subtitle {
    grid-column: 1/3;
    @include font(13, $primary-red, 700, 5);
  }
  &__wrapper {
    min-height: 120px;
    @include flex(center, $justifyContent: space-between);
    grid-column: 1/3;
    position: relative;
    box-sizing: border-box;
    color: $primary-white;
    width: 100%;
    height: 100%;
    &.error label {
      border: dashed 2px $primary-red;
    }
    & label.short {
      margin-right: 20px;
    }
    & label {
      @include flex(center, $justifyContent: center);
      @include font(12, $primary-black, 500, 15);
      @include bg(5, none);
      width: 100%;
      height: 100%;
      position: relative;
      border: dashed 2px $primary-grey;
      padding: 8px 16px 8px 8px;
      cursor: pointer;
      transition: 0.5s all;
      .uploader {
        &__wrapper {
          @include flex(center, 10, column, center);
          svg {
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.479));
          }
        }
        &__btn {
          @include flex(center, $justifyContent: center);
          width: 150px;
          height: 30px;
          background: #c1ff45c5;
          border-radius: 3px;
          transition: 0.5s all;

          &__label {
            @include font(13, $fontWeight: 400);
            text-align: center;
          }
          &:hover {
            transition: 0.5s all;
            background-color: $primary-green;
          }
        }
        &__text {
          margin: 5px;
        }
      }
      &:hover {
        transition: 0.5s all;
        background-color: $secondary-grey;
      }
    }

    .attachments__wrapper {
      width: 100%;
      padding-right: 4px;
      height: 140px;
      overflow-y: scroll;
      scroll-margin: 10px;
    }
  }
  &__card-preview {
    @include font(12);
    width: 100%;
    position: relative;
    min-height: 65px;
    border: 1px solid $primary-grey;
    border-radius: 5px;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @include img();
  }
  &__btn_delete {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    z-index: 2;
    &:hover path {
      fill: $primary-dark-black;
    }
  }
  &__icon {
    background-color: $primary-white;
    border-radius: 55px;
  }
}
@media screen and (max-width: 991px) {
  .multi-upload {
    &__title {
      grid-column: 1/2;
    }
    &__wrapper {
      grid-column: 1/2;
    }
  }
}
