@import 'src/style/mixin.scss';

@media screen and (max-width: 1120px) {
  .order-payment {
    &__container {

      &__cart-container {
        max-width: none;
      }
    }
    &__price-off {
      max-width: none;
    }
  }
}

@media screen and (max-width: 991px) {
  .order {
    padding: 0 15px 15px;
    &__header {
      grid-template-columns: 1fr;
      padding: 15px 0;
      margin: 0;
      a {
        display: none;
      }
    }
    &__store {
      @include bg-off();
      gap: 15px;
      padding: 0;
    }
    &__wrapper {
      grid-template-columns: 1fr;
      gap: 15px;
    }
  }
  .extra-card {
    grid-template-columns: 40px auto;
    gap: 7px;
    &__logo {
      width: 40px;
      height: 40px;
    }
    &__info {
      &__name {
        @include flex(center, 0, row, space-between);
        @include font(18, $primary-dark-black, 600);
      }
      &__title {
        @include font();
      }
      &__description {
        grid-column: 1 / 3;
      }
    }
    &__action {
      grid-column: 2/3;
      &__button {
        height: 30px;
        width: 100px;
      }
    }
  }
  .order-payment {
    .col-2 {
      gap: 10px;
      grid-template-columns: 1fr;
    }
    @include bg-off();
    gap: 15px;
    padding: 0;
    &__price-off {
      @include flex(stretch, 5, column, center);
      padding: 15px;
      line-height: 20px;
    }
    &__voucher {
      position: relative;
      @include font();
    }
    &__option {
      .MuiFormControlLabel-root {
        height: 30px;
      }
    }
    &__comment {
      @include bg();
      padding: 15px;
    }
    &__container {

      &__payment-container {
        line-height: inherit;
      }
    }
  }
}

@media screen and (max-width: 475px) {
  .order-payment {
    &__container {

      &__payment-container {
        margin: 10px;
      }
    }
  }
}