@import 'src/style/mixin.scss';

.step {
  width: 170px;
  height: 195px;
  padding: 15px;
  @include flex(center, 10, column, space-between);
  border-radius: 10px;
  background-color: $primary-white;

  &__docs {
    width: 100%;
    padding: 10px;

    &.missing {
      @include font(14, rgba(227, 100, 108, 1), 400);
      background-color: rgba(227, 100, 108, 0.04);
    }

    &.done {
      @include font(14, rgba(120, 218, 108, 1), 400);
      background-color: rgb(120, 218, 108, 0.08);
    }
  }

  &__status {
    width: 100%;
    @include flex(center, 10, row, center);
    padding-top: 10px;
    border-top: 1px solid #D8D9E1;

    &__title {
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;

      &.complete {
        color: $secondary-blue;
      }

      &.completed, &.closed {
        color: #8891A4;
      }
    }
  }

  h5 {
    @include font(16, $secondary-blue, 700);
  }

  h6 {
    @include font(14, #8891A4, 400, 18.5);
  }
}

.active {
  cursor: pointer;
  transition: 0.3s linear;

  &:hover {
    margin-bottom: 15px;
  }
}