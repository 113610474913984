@import 'src/style/mixin.scss';

@media screen and (max-width: 430px) {
  .cancel-modal {
    width: auto;
    margin: 150px 10px;
    padding: 30px;

    &__buttons {
      flex-direction: column;
    }
  }
}