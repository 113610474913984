@import 'src/style/mixin.scss';

.infoStepperContainer {
    overflow-x: auto;
    @media (max-width: 650px) {
        display: none !important;
      }
}

.firstStep {
    text-align: center;
    background: rgba(250, 250, 250);
    z-index: 1;
    min-width: 100px;

    &__mainText {
        margin-top: 10px;
        font-weight: 700;
        font-size: 12px;
        line-height: 22px;
        color: #4B566B
    }
    &__secondaryText {
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.6)
    }
}

.arrowButton {
    background: none;
    border: none;
    color: $primary-green;
}

.prev {
    left: -10px;
    right: auto;
    top: 60%;
}

.next {
    right: -10px;
    left: auto;
    top: 60%;
}