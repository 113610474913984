@import 'src/style/mixin.scss';

.order__contact {
  @include flex(flex-start, 20, column, flex-start);
  @include bg();
  width: 100%;
  padding: 30px;
  height: min-content;
  &__title {
    @include font(40);
  }
  &__fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    width: 100%;
  }
  div[data-title='email'] {
    grid-column: 1/2;
  }
  &__amount-seats {
    grid-column: 1/2;
  }
  &__delivery-address {
    width: 100%;
    border: 1px solid #7d879c;
    border-radius: 3px;
    padding: 20px;
  }
  &__note {
    grid-column: 1/4;
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .order__contact {
    @include flex(flex-start, 10, column, flex-start);
    padding: 15px;
    &__title {
      @include font(40);
    }
    &__fields {
      grid-template-columns: 1fr;
      gap: 10px;
    }
    &__delivery-address {
      grid-column: 1/2;
    }
    &__note {
      grid-column: 1/2;
      width: 100%;
    }
  }
}

@media screen and (max-width: 346px) {
  .remove-margin {
    margin-left: 0;
  }
}
