@import 'src/style/mixin.scss';

@media screen and (max-width: 330px) {
  .share-modal {
    width: auto;
    height: 70px;
    padding: 10px;

    &__items {
      height: 50px;
      width: 280px;
      gap: 7px;
    }
  }
}