@import 'src/style/mixin.scss';

.general-filter {
  // width: 90%;
  @include flex(center, 10, row, space-evenly);
  @include bg(0);
  padding: 10px 35px;
  border-radius: 50px;

  & > button {
    padding: 0;
    min-width: auto;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: $primary-shadow;
  }
  &__locations {
    @include flex(flex-start, 20, row, center);
  }
  .filter__title {
    margin-bottom: 0;
  }
}

.filter-sub {
  max-width: 900px;
  display: flex;
  gap: 10px 5px;
  justify-content: flex-start;
  flex-wrap: wrap;

  div {
    &:first-child {
      font-weight: 700;
    }
  }

  &__selected {
    color: $primary-white;
    background-color: rgba(8, 27, 61, 1);
  }
}

@media screen and (max-width: 1261px) {

  .general-filter-container {
    position: fixed;
    width: 100%;
    top: 38px;
    transition: top 0.3s;
    border-radius: 0;
    padding: 10px;
    @include bg(0);
  }

  .general-filter {
    display: block;
    @include bg-off();
    @include flex(center, 10, row, center);
    & > button {
      margin-top: 5px;
      padding: 0;
      width: 100%;
      height: 35px;
      border-radius: 3px;
      box-shadow: $primary-shadow;
    }
    &__locations {
      width: 100%;
      @include bg();
      padding: 7px 10px;
      @include flex(center, 27, row, center);
    }
    .filter__title {
      font-size: 15px;
    }
  }

  .filter-sub {
    max-width: none;
    margin-top: 0;
  }
}

@media screen and (max-width: 991px) {

  .general-filter {
    flex-direction: column;
  }

  .filter-sub {
    gap: 5px;
  }
}