@import "src/style/mixin.scss";

@media screen and (max-width: 1250px) {
    .smallHeader {
        &__container {
            gap: 20px;
            &__links {

              &__link {
                font-size: 16px;
              }
            }
        }
     
    }
}

@media screen and (max-width: 991px) {
  .smallHeader {

    &__wrapper {

      &_sup {
        @include flex(center, 0, column, center);
        padding: 0;
        .logo {
          @include flex(center);
          width: 60px;
          img {
            width: inherit;
          }
        }
      }
    }

    &__container {
        align-items: center;
        width: 100%;
        gap: 0;

        &__button-container {
          display: none;
            margin-left: auto;

            &__button {
                height: 40px !important;
            }
        }

        a {
            width: 110px;
        }
    }
  }
}
