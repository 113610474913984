@import 'src/style/mixin.scss';

.header {
  @include bg(0);
  @include font();
  top: 0;
  position: sticky;
  border: none;
  width: 100%;
  z-index: 100;
  transition: all 0.4s ease;
  background-color: $secondary-grey;
  &__wrapper {
    width: 1240px;
    margin: 0 auto;
    &_sup {
      align-items: center;
      justify-items: center;
      padding: 15px 0;
      top: 0;
      left: 0;
      display: flex;
      gap: 60px;
      a {
        @include flex(flex-start, 0, column, center);
        width: 100%;
        cursor: pointer;
        text-decoration: none;
        &:visited {
          color: $primary-black;
        }
      }
    }
    &_sub {
      display: flex;
      justify-content: space-between;
      padding: 11px;
    }
  }
  &__book {
    height: 45px;
    padding: 8px 16px !important;
  }
  &__icon {
    @include flex(center, 0, row, center);
    position: relative;
    width: 40px;
    height: 40px;
    background-color: $primary-green;
    border-radius: 100%;
    box-shadow: $primary-shadow;
    cursor: pointer;
    & > svg {
      fill: $primary-black;
    }
    &_count {
      position: absolute;
      top: -16%;
      right: -16%;
      @include flex(center, 0, row, center);
      @include font(12, $primary-white, 400);
      width: 20px;
      height: 20px;
      background-color: $primary-red;
      border-radius: 100%;
    }
  }
  &__icon-set {
    @include flex(center, 15, row, center);
  }
  &__mobile_icon-set {
    display: none;

    button {
      padding: 0 15px !important;
      height: 30px !important;
    }
  }
  .dash {
    margin: 0 10px;
    transform: translateY(90%);
  }
}
.header__filter__type {
  gap: 40px;
  display: flex;
}
