@import 'src/style/mixin.scss';

.filter-aside {
  width: 100%;
  @include bg();
  display: grid;
  padding: 20px;
  grid-column: 1/2;
  .filter-type {
    width: 100%;
    // @media (max-width: 461px) {
    //   width: 200px;
    // }
  }
  .filter-price {
    &__wrapper {
      @include flex(center, 8, row, center);
      & > div {
        width: 100%;
      }
    }
  }
  // .filter-features {
  //   @media (max-width: 461px) {
  //     width: 200px;
  //   }
  // }
  .filter-color {
    &__wrapper {
      @include flex(center, 8);
    }
    &__item {
      border-radius: 100%;
      width: 26px;
      height: 26px;
      @include hover(none, none, none, 0 0 3px grey);
    }
  }
  .filter-hostRating {
    &__box {
      @include flex(center, 2);
    }
  }
  .filter-extra {
    @include flex(flex-start, 0, column, center);
    .filter__title {
      margin-bottom: 10px;
    }
  }
  .filter-reviews {
    margin-bottom: 10px;
    @include flex(center, 10, column, center);
  }
}

.MuiFormControl-root {
  max-width: none !important;
}