@import 'src/style/mixin.scss';

.payments-card {
  @include flex(flex-start, 0, column, space-between);

  &__container {
    width: 100%;

    p {
      @include font(16, $primary-black, 400, 18);
      margin-bottom: 10px;
    }
  }

  &__payment {
    width: 100%;
    @include flex(center, 10, row, space-between);

    &__receipt {
      @include font(14, $primary-black, 400);
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: $primary-dark-black;
      }
    }
  }
  
  &__footer {
    width: 100%;
    @include flex(center, 10, row, space-between);
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #ccc;
  }

  &__title {
    @include font(16, $primary-black, 700);
  }

  &__price {
    font-weight: 700;
    margin-left: 10px;
  }
}