@import "src/style/mixin.scss";

.small-footer {
  position: absolute;
  @include flex(center, 10, row, space-around);  
  bottom: 0;
  margin-top: 25px;
  width: 100%;
  padding: 20px 0;
  background: #0C0E30;

  &__items {
    @include flex(center, 10, row, center);  

    a {
      display: inline-block;     
      border-right: 1px solid #acb6c9;
      padding-right: 10px;
      @include font(15, $primary-white !important);
      
      &:last-child {
        border-right: unset;
      }
      &:hover {
        color: #acb6c9;
      }
    }
  }

  h6 {
    @include font(14, $primary-white, 400);
  }
}