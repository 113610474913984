@import 'src/style/mixin.scss';

.common-card {
  @include flex(flex-start, 5, column, space-between);

  &__container {
    @include flex(flex-start, 5, column, center);
  }

  &__change {
    margin-top: 20px;
    @include font(16, $primary-black, 700);

    a {
      margin-right: 5px;
      color: rgb(0, 127, 255) !important;
      text-decoration: underline !important;
    }
  }
}