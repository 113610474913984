@import 'src/style/mixin.scss';

.articles-card {
  grid-column: 1/2;
  grid-row: 2/4;

  &__van {

    &__container {
      @include flex(flex-start, 10, row, space-around);
    }

    &__price {
      width: 150px;
      height: 115px;
      @include flex(center, 10, column, center);
      border: 1px solid #D8D9E1;
      border-radius: 5px;

      h6 {
        @include font(24, $primary-black, 500);
      }

      span {
        @include font(12, $primary-black, 500, 2);
      }
    }

    &__title {
      margin: 10px 0;
      @include font(14, $primary-black, 500);

      a {
        margin-left: 5px;
        color: rgb(0, 127, 255) !important;
        text-decoration: underline !important;
      }
    }

    img {
      width: 150px;
      height: 115px;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  &__fees {
    margin-top: 20px;

    &__fee {
      @include flex(center, 0, row, space-between);
      @include font(16, $primary-black, 400);
      padding-top: 10px;
      margin-top: 10px;
      border-top: 1px solid #ccc;

      &__container {
        @include flex(center, 5, row, center);

        img {
          width: 20px;
          height: 20px;
          border-radius: 5px;
        }
      }
    }
  }

  &__total {
    @include flex(center, 0, row, space-between);
    @include font(16, $primary-black, 400);
    padding-top: 20px;
    margin-top: 10px;
    border-top: 1px solid #ccc;
  }
}