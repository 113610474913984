@import 'src/style/mixin.scss';

.payment {
  max-width: 700px;
  padding-top: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 300px;

  &__description {
    font-size: 18px;
    color: #7d879c;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 30px;
  }
}